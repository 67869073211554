import React, {Suspense, useState, useEffect} from 'react';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom';

import {ThemeProvider} from '@material-ui/styles';
import {ClimbingBoxLoader} from 'react-spinners';
import Cookies from 'js-cookie';
import {getConfigsUser, getPms, getConfigsIcal} from 'helpers/api';
import CardCookies from 'components/CardCookies';
import {useSnackbar} from 'notistack';
import MuiTheme from './theme';
import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
} from './layout-blueprints';
import Login from './pages/Login';
import LoginDsl from './pages/LoginDsl';
import PagesError404 from './pages/NotFound';
import User from './pages/User';
import AddUser from './pages/UserAdd';
import EditUser from './pages/UserEdit';
import EditPassword from './pages/UserEditPassword';
import TypeActivity from './pages/TypeActivity';
import AddTypeActivity from './pages/TypeActivityAdd';
import EditTypeActivity from './pages/TypeActivityEdit';
import Occupation from './pages/Occupation';
import OccupationEdit from './pages/OccupationEdit';
import OccupationAdd from './pages/OccupationAdd';
import Activity from './pages/Activity';
import ActivityEdit from './pages/ActivityEdit';
import ActivityNew from './pages/ActivityAdd';
import Home from './pages/Home';
import RouteHandler from './components/RouteHandler';
import Profile from './pages/Profile';
import AddProfile from './pages/ProfileAdd';
import EditProfile from './pages/ProfileEdit';
import Holiday from './pages/Holiday';
import AddHoliday from './pages/HolidayAdd';
import EditHoliday from './pages/HolidayEdit';
import Config from './pages/CompanyConfig';
import Calendar from './pages/Calendar';
import BackDrop from './components/backDrop/index';
import {accepted, setCookie} from './helpers/auth';
import PlaceOfSale from './pages/PlaceOfSale';
import AddPlaceOfSale from './pages/PlaceOfSaleAdd';
import EditPlaceOfSale from './pages/PlaceOfSaleEdit';
import SchedulePms from './pages/SchedulePms';
import Customer from './pages/Customer';
import CustomerView from './components/forms/Customer';
import ScheduleNew from './components/forms/ScheduleNew';

const SuspenseLoading = () => (
  <>
    <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
      <div className="d-flex align-items-center flex-column px-4">
        <ClimbingBoxLoader color="#5383ff" loading />
      </div>
      <div className="text-muted font-size-xl text-center pt-3">Carregando</div>
    </div>
  </>
);

const Routes = () => {
  const {enqueueSnackbar} = useSnackbar();
  const location = useLocation();
  const [configs, setConfigs] = useState({});
  const [observer, setObserver] = useState(false);
  const [bar, setBar] = useState('#3d4977');
  const [primary, setPrimary] = useState('#e4e7ed');
  const [op, setOp] = useState(false);
  const [pms, setPms] = useState();
  const [opCookie, setOpCookies] = useState(accepted);
  const [companyConfigs, setCompanyConfigs] = useState({});

  const openAlert = async (msg, variant = 'info') => {
    enqueueSnackbar(msg, {
      variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      preventDuplicate: true,
      autoHideDuration: 6000,
    });
  };

  useEffect(() => {
    if (Cookies.get('token')) {
      const getOne = async () => {
        const res = await getConfigsUser();
        if (res) {
          setConfigs(res.permission);
        }
        const p = await getPms();
        if (p) {
          setPms(p.pms);
        }
        const resConf = await getConfigsIcal();
        setCompanyConfigs(resConf);
        setBar(resConf.barcolor);
        setPrimary(resConf.primarycolor);
      };
      getOne();
    }
    // eslint-disable-next-line
  }, [observer]);

  const handleOpen = () => {
    setCookie();
    setOpCookies(false);
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Redirect exact from="/" to={`/company/${Cookies.get('company')}`} />
          <RouteHandler path="/company/:id">
            <MinimalLayout>
              <Switch location={location} key={location.pathname}>
                <RouteHandler exact path="/company/:id">
                  <Login observer={observer} setObserver={setObserver} />
                </RouteHandler>
              </Switch>
            </MinimalLayout>
            {opCookie && (
              <CardCookies open={opCookie} handleOpen={handleOpen} />
            )}
          </RouteHandler>

          <Route
            path={[
              '/home',
              '/user',
              '/user/new',
              '/user/edit',
              '/user/change/password',
              '/typeactivity',
              '/typeactivity/new',
              '/typeactivity/edit',
              '/occupation',
              '/occupation/new',
              '/occupation/edit',
              '/activity',
              '/activity/new',
              '/activity/edit',
              '/profile',
              '/profile/new',
              '/profile/edit',
              '/holiday',
              '/holiday/new',
              '/holiday/edit/:id',
              '/config/company',
              '/placeofsale',
              '/placeofsale/new',
              '/placeofsale/edit/:id',
              '/customer/:id',
              '/schedule/new',
            ]}>
            <LeftSidebar
              bar={bar}
              companyConfigs={companyConfigs}
              pms={pms}
              openAlert={openAlert}
              configs={configs}>
              {op && <BackDrop open={op} />}
              <Switch location={location} key={location.pathname}>
                <RouteHandler path="/home" private>
                  <Home
                    companyConfigs={companyConfigs}
                    openAlert={openAlert}
                    setOp={setOp}
                  />
                </RouteHandler>
                <RouteHandler exact path="/user" private configs={configs}>
                  <User setOp={setOp} openAlert={openAlert} pms={pms} />
                </RouteHandler>
                <RouteHandler path="/user/new" private configs={configs}>
                  <AddUser setOp={setOp} openAlert={openAlert} pms={pms} />
                </RouteHandler>
                <RouteHandler path="/user/edit/:id" private configs={configs}>
                  <EditUser setOp={setOp} openAlert={openAlert} pms={pms} />
                </RouteHandler>

                <RouteHandler
                  path="/user/change/password"
                  private
                  configs={configs}>
                  <EditPassword openAlert={openAlert} />
                </RouteHandler>

                <RouteHandler
                  exact
                  path="/typeactivity"
                  private
                  configs={configs}>
                  <TypeActivity openAlert={openAlert} />
                </RouteHandler>

                <RouteHandler
                  path="/typeactivity/new"
                  private
                  configs={configs}>
                  <AddTypeActivity openAlert={openAlert} />
                </RouteHandler>
                <RouteHandler
                  path="/typeactivity/edit/:id"
                  private
                  configs={configs}>
                  <EditTypeActivity openAlert={openAlert} />
                </RouteHandler>

                <RouteHandler
                  exact
                  path="/occupation"
                  private
                  configs={configs}>
                  <Occupation openAlert={openAlert} />
                </RouteHandler>

                <RouteHandler path="/occupation/new" private configs={configs}>
                  <OccupationAdd openAlert={openAlert} />
                </RouteHandler>
                <RouteHandler
                  path="/occupation/edit/:id"
                  private
                  configs={configs}>
                  <OccupationEdit openAlert={openAlert} />
                </RouteHandler>

                <RouteHandler exact path="/activity" private configs={configs}>
                  <Activity
                    pms={pms}
                    setOp={setOp}
                    companyConfigs={companyConfigs}
                    openAlert={openAlert}
                  />
                </RouteHandler>

                <RouteHandler path="/activity/new" private configs={configs}>
                  <ActivityNew
                    pms={pms}
                    primary={primary}
                    setOp={setOp}
                    companyConfigs={companyConfigs}
                    openAlert={openAlert}
                  />
                </RouteHandler>
                <RouteHandler
                  path="/activity/edit/:id"
                  private
                  configs={configs}>
                  <ActivityEdit
                    idcompany={companyConfigs.idcompany}
                    primary={primary}
                    pms={pms}
                    setOp={setOp}
                    companyConfigs={companyConfigs}
                    openAlert={openAlert}
                  />
                </RouteHandler>

                <RouteHandler exact path="/profile" private configs={configs}>
                  <Profile openAlert={openAlert} />
                </RouteHandler>

                <RouteHandler path="/profile/new" private configs={configs}>
                  <AddProfile pms={pms} openAlert={openAlert} />
                </RouteHandler>
                <RouteHandler
                  path="/profile/edit/:id"
                  private
                  configs={configs}>
                  <EditProfile pms={pms} openAlert={openAlert} />
                </RouteHandler>

                <RouteHandler exact path="/holiday" private configs={configs}>
                  <Holiday openAlert={openAlert} />
                </RouteHandler>
                <RouteHandler path="/holiday/new" private configs={configs}>
                  <AddHoliday openAlert={openAlert} />
                </RouteHandler>
                <RouteHandler
                  path="/holiday/edit/:id"
                  private
                  configs={configs}>
                  <EditHoliday openAlert={openAlert} />
                </RouteHandler>

                <RouteHandler path="/config/company" private configs={configs}>
                  <Config setOp={setOp} setPms={setPms} openAlert={openAlert} />
                </RouteHandler>

                <RouteHandler
                  exact
                  path="/placeofsale"
                  private
                  configs={configs}>
                  <PlaceOfSale openAlert={openAlert} pms={pms} />
                </RouteHandler>
                <RouteHandler path="/placeofsale/new" private configs={configs}>
                  <AddPlaceOfSale openAlert={openAlert} pms={pms} />
                </RouteHandler>
                <RouteHandler
                  path="/placeofsale/edit/:id"
                  private
                  configs={configs}>
                  <EditPlaceOfSale openAlert={openAlert} pms={pms} />
                </RouteHandler>

                <RouteHandler
                  exact
                  path="/customer/:id"
                  private={false}
                  configs={configs}>
                  <CustomerView
                    companyConfigs={companyConfigs}
                    pms={pms}
                    openAlert={openAlert}
                    setOp={setOp}
                  />
                </RouteHandler>

                <RouteHandler
                  exact
                  path="/schedule/new"
                  private
                  configs={configs}>
                  <ScheduleNew
                    companyConfigs={companyConfigs}
                    pms={pms}
                    openAlert={openAlert}
                    setOp={setOp}
                  />
                </RouteHandler>
              </Switch>
            </LeftSidebar>
            {opCookie && (
              <CardCookies open={opCookie} handleOpen={handleOpen} />
            )}
          </Route>

          <Route path={['/calendar', '/pms/schedule', '/customer']}>
            <CollapsedSidebar
              bar={bar}
              companyConfigs={companyConfigs}
              pms={pms}
              openAlert={openAlert}
              configs={configs}>
              {op && <BackDrop open={op} />}
              <Switch location={location} key={location.pathname}>
                <RouteHandler exact path="/calendar" private configs={configs}>
                  <Calendar
                    pms={pms}
                    companyConfigs={companyConfigs}
                    openAlert={openAlert}
                    setOp={setOp}
                  />
                </RouteHandler>

                <RouteHandler
                  exact
                  path="/pms/schedule"
                  private={false}
                  configs={configs}>
                  <SchedulePms
                    companyConfigs={companyConfigs}
                    pms={pms}
                    openAlert={openAlert}
                    setOp={setOp}
                  />
                </RouteHandler>

                <RouteHandler
                  exact
                  path="/customer"
                  private={false}
                  configs={configs}>
                  <Customer pms={pms} openAlert={openAlert} setOp={setOp} />
                </RouteHandler>
              </Switch>
            </CollapsedSidebar>
          </Route>

          <Route exact path="/desbravador">
            {op && <BackDrop open={op} />}
            <LoginDsl openAlert={openAlert} setOp={setOp} />
          </Route>

          <Route component={PagesError404} />

          {/* <CardCookies open={false} handleOpen={handleOpen}/>
            <CardCookies open={true} handleOpen={handleOpen}/>
            <CardCookies open={null} handleOpen={handleOpen}/> */}
        </Switch>
      </Suspense>
    </ThemeProvider>
  );
};

export default Routes;
