/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, {Fragment, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
  getAllUsers,
  getActiveTypeActivities,
  getActiveActivities,
  getFilteredSchedule,
  getFilteredSchedulePdf,
} from 'helpers/api';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {FaEllipsisV, FaFilter, FaPlus} from 'react-icons/fa';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MomentUtils from '@date-io/moment';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import PrintIcon from '@material-ui/icons/Print';
import {Page} from 'store/reducers/Page/pageAction';
import {
  Grid,
  Fab,
  Typography,
  Divider,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  Menu,
  // IconButton,
  // Card,
  // CardContent,
  // Button,
  // Tooltip
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import PersonIcon from '@material-ui/icons/Person';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import Enum from 'helpers/enum';
import CalendarsFullWidthBasic from './CalendarsFullWidthBasic';

function LivePreviewExample(props) {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [typeAct, setTypeAct] = useState([]);
  const [act, setAct] = useState([]);
  const [opt1, setOpt1] = useState(false);
  const [opt2, setOpt2] = useState(false);
  const [opt3, setOpt3] = useState(false);
  const [opt4, setOpt4] = useState(false);
  const [nro, setNro] = useState('');
  const [usersID, setUsersID] = useState([]);
  const [usersName, setUsersName] = useState([]);
  const [typeActID, setTypeActID] = useState([]);
  const [typeActName, setTypeActName] = useState([]);
  const [actID, setActID] = useState([]);
  const [actName, setActName] = useState([]);
  const [events, setEvents] = useState([]);
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const [firstD, setFirstD] = useState(
    moment(new Date(y, m, 1)).format('YYYY/MM/DD'),
  );
  const [lastD, setLastD] = useState(
    moment(new Date(y, m + 1, 0)).format('YYYY/MM/DD'),
  );
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const [maxDate, setMaxDate] = useState(new Date());

  moment.locale(i18n.language);

  const getAll = async (
    idtypeactivity = [],
    idactivity = [],
    iduser = [],
    status = [],
  ) => {
    props.setOp(true);
    const newArr = [];
    const res = await getFilteredSchedule(
      firstD,
      lastD,
      idtypeactivity,
      idactivity,
      iduser,
      status,
    );
    if (res && !res.errors) {
      res.map((i) =>
        newArr.push({
          ...i,
          start: new Date(i.start),
          end: new Date(i.end),
        }),
      );
    } else if (res.errors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
    props.setOp(false);
    setEvents(newArr);
  };

  const getByDate = async (first, last, status) => {
    props.setOp(true);
    const newArr = [];
    const res = await getFilteredSchedule(
      first,
      last,
      typeActID,
      actID,
      usersID,
      status,
      nro,
    );
    if (res && !res.errors) {
      res.map((i) =>
        newArr.push({
          ...i,
          start: new Date(i.start),
          end: new Date(i.end),
        }),
      );
      setEvents(newArr);
      props.setOp(false);
    } else if (res.errors) {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
  };

  const getByDatePdf = async (first, last, status, status2, typeFilter) => {
    const res = await getFilteredSchedulePdf(
      first,
      last,
      typeActID,
      typeActName,
      actID,
      actName,
      usersID,
      usersName,
      status,
      status2,
      typeFilter,
      nro,
    );
    if (res && !res.errors) {
      const pdfWindow = window.open('');
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
          res,
        )}'></iframe>`,
      );
      setAnchorElMenu(false);
      await getByDate(firstD, lastD, status);
    } else {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
  };

  useEffect(() => {
    dispatch(Page(t('word.calendar')));
    setMaxDate(
      new Date(
        `${maxDate.getFullYear() + 2}-${
          maxDate.getMonth() + 1
        }-${maxDate.getDate()} 01:00:00`,
      ),
    );

    const getUsers = async () => {
      const res = await getAllUsers();
      if (res && !res.errors) {
        setUsers(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
    };

    const getTypeActivities = async () => {
      const res = await getActiveTypeActivities();
      if (res && !res.errors) {
        setTypeAct(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
    };

    const getActivities = async () => {
      const res = await getActiveActivities();
      if (res && !res.errors) {
        setAct(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
    };

    getAll();
    getTypeActivities();
    getActivities();
    getUsers();
    // eslint-disable-next-line
  }, []);

  const handleUsers = (value) => {
    const arr = [];
    const arr2 = [];
    value.map((i) => arr.push(i.iduser));
    value.map((i) => arr2.push(i.name));
    setUsersID(arr);
    setUsersName(arr2);
  };

  const handleTypeActs = (value) => {
    const arr = [];
    const arr2 = [];
    value.map((i) => arr.push(i.idtypeactivity));
    value.map((i) => arr2.push(i.typeactivity));

    setTypeActID(arr);
    setTypeActName(arr2);
  };

  const handleActs = (value) => {
    const arr = [];
    const arr2 = [];
    value.map((i) => arr.push(i.idactivity));
    value.map((i) => arr2.push(i.activity));

    setActID(arr);
    setActName(arr2);
  };

  const handleFilters = async () => {
    props.setOp(true);
    const arr = [
      ...(opt1 ? [Enum.schedule_status.scheduled] : []),
      ...(opt2 ? [Enum.schedule_status.canceled] : []),
      ...(opt3 ? [Enum.schedule_status.inprogress] : []),
      ...(opt4 ? [Enum.schedule_status.finished] : []),
    ];
    await getByDate(firstD, lastD, arr);
    props.setOp(false);
  };

  const handleFiltersPdf = async (tpFilter) => {
    props.setOp(true);
    const arr = [
      ...(opt1 ? [Enum.schedule_status.scheduled] : []),
      ...(opt2 ? [Enum.schedule_status.canceled] : []),
      ...(opt3 ? [Enum.schedule_status.inprogress] : []),
      ...(opt4 ? [Enum.schedule_status.finished] : []),
    ];
    const arr2 = [
      ...(opt1 ? [t('word.schedule-status.0')] : []),
      ...(opt2 ? [t('word.schedule-status.1')] : []),
      ...(opt3 ? [t('word.schedule-status.2')] : []),
      ...(opt4 ? [t('word.schedule-status.3')] : []),
    ];
    await getByDatePdf(firstD, lastD, arr, arr2, tpFilter);
    props.setOp(false);
  };

  const handleFilter = (e) => {
    const filt = e;
    handleFiltersPdf(filt);
  };

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);

  const toggleSidebarMenu = () => setIsSidebarMenuOpen(!isSidebarMenuOpen);

  return (
    <>
      {/* <Grid container> */}
      <div className="app-inner-content-layout app-inner-content-layout-fixed">
        <div className="d-flex d-lg-none p-4 order-0 justify-content-between align-items-center">
          <Fab onClick={toggleSidebarMenu} color="primary" size="small">
            <FaEllipsisV
              icon={['fas', 'ellipsis-v']}
              className="font-size-sm"
            />
          </Fab>
        </div>
        <div
          className={clsx(
            'app-inner-content-layout--sidebar app-inner-content-layout--sidebar__lg bg-secondary border-right',
            {'layout-sidebar-open': isSidebarMenuOpen},
          )}>
          <PerfectScrollbar>
            <div className="px-4 pt-4 pb-3">
              <Typography
                color="primary"
                component="div"
                className="d-flex align-items-center">
                {/* <div className="text-first font-weight-bold">
                  Calendar Eventss
                </div> */}
                <div
                  className="ml-auto font-size-xs"
                  style={{paddingLeft: '80px'}}>
                  <Fab
                    size="small"
                    aria-label="add"
                    color="secondary"
                    onClick={() => handleFilters()}>
                    <FaFilter />
                  </Fab>
                </div>
                <div className="ml-auto font-size-xs">
                  <Fab
                    size="small"
                    disabled={events.length < 1}
                    aria-label="add"
                    color="secondary"
                    onClick={handleClickMenu}>
                    <PrintIcon />
                  </Fab>
                </div>
                <div className="ml-auto font-size-xs">
                  <Link to="/schedule/new">
                    <Fab
                      size="small"
                      aria-label="add"
                      color="secondary"
                      title={t('word.schedule-new')}>
                      <FaPlus />
                    </Fab>
                  </Link>
                </div>
                <div>
                  <Menu
                    anchorEl={anchorElMenu}
                    keepMounted
                    open={Boolean(anchorElMenu)}
                    onClose={handleCloseMenu}
                    classes={{list: 'p-0'}}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}>
                    <div className="overflow-hidden dropdown-menu-xl">
                      <List className="nav-danger nav-pills flex-column p-3">
                        <Typography
                          className="text-black py-2 px-3 font-weight-bold"
                          component="div">
                          {t('word.vision')}
                        </Typography>
                        <ListItem
                          onClick={() => handleFilter(0)}
                          button
                          className="rounded-sm">
                          <div className="nav-link-icon opacity-6">
                            <BookmarkBorderIcon />
                          </div>
                          <span>{t('word.date-activity')}</span>
                        </ListItem>
                        <ListItem
                          onClick={() => handleFilter(1)}
                          button
                          className="rounded-sm">
                          <div className="nav-link-icon opacity-6">
                            <PersonIcon />
                          </div>
                          <span>{t('word.date-professional')}</span>
                        </ListItem>
                      </List>
                    </div>
                  </Menu>
                </div>
              </Typography>

              <Grid item direction="column">
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                  locale={i18n.language}>
                  <DatePicker
                    label={t('word.date1')}
                    className="m-1"
                    variant="inline"
                    disableToolbar
                    value={firstD}
                    maxDate={maxDate}
                    onChange={(e) => setFirstD(moment(e).format('YYYY/MM/DD'))}
                    format="L"
                    autoOk
                    invalidDateMessage={t('message.invalid-date')}
                  />
                  <DatePicker
                    label={t('word.date2')}
                    className="m-1"
                    variant="inline"
                    disableToolbar
                    value={lastD}
                    maxDate={maxDate}
                    onChange={(e) => setLastD(moment(e).format('YYYY/MM/DD'))}
                    format="L"
                    autoOk
                    invalidDateMessage={t('message.invalid-date')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <div className="text-first font-weight-bold mt-2">
                {t('word.status')}
              </div>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={opt1}
                        onChange={(e) => setOpt1(e.target.checked)}
                        value={Enum.schedule_status.scheduled}
                      />
                    }
                    label={t('word.schedule-status.0')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={opt2}
                        onChange={(e) => setOpt2(e.target.checked)}
                        value={Enum.schedule_status.canceled}
                      />
                    }
                    label={t('word.schedule-status.1')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={opt3}
                        onChange={(e) => setOpt3(e.target.checked)}
                        value={Enum.schedule_status.inprogress}
                      />
                    }
                    label={t('word.schedule-status.2')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={opt4}
                        onChange={(e) => setOpt4(e.target.checked)}
                        value={Enum.schedule_status.finished}
                      />
                    }
                    label={t('word.schedule-status.3')}
                  />
                </FormGroup>
              </FormControl>
              <div className="divider mt-2" />
              <div className="p-4 bg-white">
                <Typography
                  color="primary"
                  component="div"
                  className="d-flex pb-3 align-items-center">
                  <div className="text-first font-weight-bold">
                    {t('word.type-activities')}
                  </div>
                  <div className="ml-auto font-size-xs" />
                </Typography>
                <Grid container spacing={4} className="font-size-xs">
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={typeAct}
                      onChange={(event, newValue) => {
                        handleTypeActs(newValue);
                      }}
                      getOptionLabel={(option) => option.typeactivity}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.typeactivity}
                            {...getTagProps({index})}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('word.select')}
                          variant="outlined"
                          placeholder={t('word.type-activities')}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              <Divider />
              <div className="p-4 bg-white">
                <Typography
                  color="primary"
                  component="div"
                  className="d-flex pb-3 align-items-center">
                  <div className="text-first font-weight-bold">
                    {t('word.activities')}
                  </div>
                  <div className="ml-auto font-size-xs" />
                </Typography>
                <Grid container spacing={4} className="font-size-xs">
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={act}
                      onChange={(event, newValue) => {
                        handleActs(newValue);
                      }}
                      getOptionLabel={(option) => option.activity}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.activity}
                            {...getTagProps({index})}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('word.select')}
                          variant="outlined"
                          placeholder={t('word.activities')}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              <Divider />
              <div className="p-4">
                <Typography
                  color="primary"
                  component="div"
                  className="d-flex pb-3 align-items-center">
                  <div className="text-first font-weight-bold">
                    {t('word.professionals')}
                  </div>
                  <div className="ml-auto font-size-xs" />
                </Typography>
                <Grid container spacing={4} className="font-size-xs">
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="fixed-tags-demo"
                      options={users}
                      onChange={(event, newValue) => {
                        handleUsers(newValue);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip label={option.name} {...getTagProps({index})} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('word.select')}
                          variant="outlined"
                          placeholder={t('word.professionals')}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              {Enum.arrPms.includes(props.pms) && (
                <>
                  <Divider />
                  <div className="p-4">
                    <Typography
                      color="primary"
                      component="div"
                      className="d-flex pb-3 align-items-center">
                      <div className="text-first font-weight-bold">
                        {t('word.N-account')}
                      </div>
                      <div className="ml-auto font-size-xs" />
                    </Typography>
                    <Grid container spacing={4} className="font-size-xs">
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-helperText"
                          label={t('word.N-account')}
                          variant="outlined"
                          value={nro}
                          type="number"
                          onChange={(e) => setNro(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </>
              )}
            </div>
          </PerfectScrollbar>
        </div>
        <div className="app-inner-content-layout--main bg-white p-0 card-box">
          <PerfectScrollbar>
            <div className="p-4">
              <CalendarsFullWidthBasic
                companyConfigs={props.companyConfigs}
                events={events}
              />
            </div>
          </PerfectScrollbar>
        </div>
        {/* <div
          onClick={toggleSidebarMenu}
          className={clsx('sidebar-inner-layout-overlay', {
            active: isSidebarMenuOpen,
          })}
        /> */}
      </div>
    </>
  );
}

export default React.memo(LivePreviewExample);
