/* eslint-disable no-restricted-syntax */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from 'react';
import {
  getActivitiesActive,
  getAvailability,
  CustomerFilter,
  getPersonCustomer,
  addSchedule,
  getCustomersAccountPms,
  addCustomerPms45,
} from 'helpers/api';
import {
  Grid,
  Card,
  TextField,
  MenuItem,
  Button,
  Fab,
  Box,
  Typography,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Page} from 'store/reducers/Page/pageAction';

import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import MuiAlert from '@material-ui/lab/Alert';
import {useTheme} from '@material-ui/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import ModalFeedback from 'components/modals/modalFeedback';
import {FaFilter, FaUserPlus} from 'react-icons/fa';
import Enum from '../../../helpers/enum';
import BackDrop from '../../backDrop';
import ModalCustomersPms45 from '../../modals/CustomersPms45';

const useStyles = makeStyles(() => ({
  error: {
    margin: '10px 0px',
    backgroundColor: '#F2DEDE',
    color: '#A94442',
    border: '1px solid #EBCCD1',
    padding: '10px',
  },
  root: {
    flexGrow: 1,
    backgroundColor: '#FFF',
  },
}));
function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Formulario(props) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState('');
  const [minDate, setMinDate] = useState(new Date());
  const [idactivity, setIdActivity] = useState(0);
  const [activities, setActivities] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedProf, setSelectedProf] = useState(null);
  const [overlayLoading, setOverlayLoading] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [name, setName] = useState('');
  const [document, setDocument] = useState('');
  const [nro, setNro] = useState('');
  const [uhpms, setUhpms] = useState('');
  const [person, setPerson] = useState([]);
  const [idperson, setIdPerson] = useState(0);
  const [disable, setDisable] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalCustormersVisible, setModalCustomersVisible] = useState(false);
  const [customersPms, setCustomersPms] = useState([]);

  const activity = useMemo(
    () => activities.find((option) => option.idactivity === idactivity),
    [idactivity, activities],
  );

  const select = async (j, k) => {
    if (
      selectedKey === k &&
      selectedProf === j['professionals.idprofessional']
    ) {
      setSelectedProf(null);
      setSelectedKey(null);
      setSelectedPeriod(null);
      return;
    }
    setSelectedProf(j['professionals.idprofessional']);
    setSelectedKey(k);
    setSelectedPeriod(j);
  };

  const loadAvailability = async () => {
    setPeriods([]);
    setSelectedPeriod(null);

    if (idactivity === 0) return;

    setOverlayLoading(true);

    const day = moment(selectedDate).day();
    const date = new Date(selectedDate);
    try {
      const response = await getAvailability(
        idactivity,
        day,
        date,
        undefined,
        undefined,
      );
      setPeriods(response);
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors.forEach((i) => {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        });
      }
      setPeriods([]);
    }

    setOverlayLoading(false);
  };

  const loadCustormersPms = async () => {
    setCustomersPms([]);
    setOverlayLoading(true);
    try {
      const response = await getCustomersAccountPms();
      setCustomersPms(response.data);
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors.forEach((i) => {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        });
      }
    }
    setOverlayLoading(false);
  };

  useEffect(() => {
    loadAvailability();
  }, [selectedDate, idactivity]);

  useEffect(() => {
    setIdPerson(0);
    if (person.length > 0) setIdPerson(person[0].idperson);
  }, [person]);

  useEffect(() => {
    setDisable(idperson === 0 || selectedProf === null);
  }, [idperson, selectedProf]);

  const Customers = async () => {
    setOverlayLoading(true);
    const body = {
      nro,
      document,
      name,
      uhpms,
    };

    const res = await CustomerFilter(body);
    if (res && !res.errors) {
      setCustomers(res);
    } else if (res.errors) {
      setCustomers([]);
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    }
    setOverlayLoading(false);
  };

  useEffect(() => {
    dispatch(Page(t('word.schedule-new')));

    const Activities = async () => {
      const res = await getActivitiesActive();
      if (res && !res.errors) {
        setActivities(res);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
    };

    Activities();
    Customers();

    if (props.pms === Enum.pms.dsl45) loadCustormersPms();
  }, []);

  const Person = async (id) => {
    setOverlayLoading(true);

    const res = await getPersonCustomer(id);
    if (res.errors) {
      for (const i of res.errors) {
        props.openAlert(
          t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
          i.color,
        );
      }
    } else {
      setPerson(res);
    }

    setOverlayLoading(false);
  };

  const clickCustomer = (id) => {
    Person(id);
  };

  const clickFilter = () => {
    Customers();
  };

  const clickCustomersPms = () => {
    setModalCustomersVisible(true);
  };

  const clickSchedule = async () => {
    setOverlayLoading(true);

    try {
      const response = await addSchedule(
        activity.idactivity,
        selectedDate,
        selectedPeriod['professionals.idprofessional'],
        selectedPeriod['periods.starttime'],
        selectedPeriod['periods.endtime'],
        idperson,
        true,
      );

      if (response.errors) {
        for (const i of response.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      } else {
        setModalVisible(true);
      }
    } catch {
      // TODO
    }

    setOverlayLoading(false);
  };

  const handleReload = () => {
    setModalVisible(!modalVisible);
    loadAvailability();
  };

  const handleClose = () => {
    setModalVisible(!modalVisible);
    setIdActivity(0);
    setPeriods([]);
    setSelectedPeriod(null);
  };

  const handleCustomersClose = () => {
    setModalCustomersVisible(!modalCustormersVisible);
  };

  const clickImportCustomer45 = async (customerPms45) => {
    try {
      const res = await addCustomerPms45(customerPms45);
      if (res && !res.errors) {
        setName('');
        setDocument('');
        setNro(customerPms45.idconta);
        setUhpms('');
        setModalCustomersVisible(false);
      } else if (res.errors) {
        for (const i of res.errors) {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        }
      }
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors.forEach((i) => {
          props.openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        });
      }
    }
  };

  return (
    <>
      {modalCustormersVisible === true && (
        <ModalCustomersPms45
          visible={modalCustormersVisible}
          customers={customersPms}
          clickImportCustomer={clickImportCustomer45}
          openAlert={props.openAlert}
          setOp={props.setOp}
          handleClose={handleCustomersClose}
        />
      )}

      <ModalFeedback
        visible={modalVisible}
        date={selectedDate}
        periodSelected={selectedPeriod}
        handleReload={() => {
          handleReload();
        }}
        handleClose={handleClose}
      />

      <BackDrop open={overlayLoading} />

      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          <Card className="p-4 mb-4">
            <Grid container xs={12} style={{marginBottom: '12px'}}>
              <Grid item xs={11}>
                <div className="text-first font-weight-bold mt-2">
                  {t('word.customer')}
                </div>
              </Grid>
              <Grid item xs={1} align="right">
                <Fab
                  size="small"
                  color="secondary"
                  title={t('word.search')}
                  onClick={clickFilter}>
                  <span className="btn-wrapper--icon">
                    <FaFilter />
                  </span>
                </Fab>
                <Fab
                  visible={props.pms === Enum.pms.dsl45}
                  size="small"
                  color="secondary"
                  title={t('word.pms-customer-accounts')}
                  onClick={clickCustomersPms}>
                  <span className="btn-wrapper--icon">
                    <FaUserPlus />
                  </span>
                </Fab>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={4}
              className="font-size-xs"
              style={{marginBottom: '12px'}}>
              <Grid item xs={5}>
                <TextField
                  id="outlined-helperText"
                  label={t('word.name')}
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{width: '100%'}}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="outlined-helperText"
                  label={t('word.document')}
                  variant="outlined"
                  value={document}
                  onChange={(e) => setDocument(e.target.value)}
                  style={{width: '100%'}}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="outlined-helperText"
                  label={t('word.N-account')}
                  variant="outlined"
                  value={nro}
                  type="number"
                  onChange={(e) => setNro(e.target.value)}
                  style={{width: '100%'}}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="outlined-helperText"
                  label={t('word.uhpms')}
                  variant="outlined"
                  value={uhpms}
                  onChange={(e) => setUhpms(e.target.value)}
                  style={{width: '100%'}}
                />
              </Grid>
            </Grid>

            <TableContainer className="mb-4" component={Paper}>
              <Table aria-label="simple table table-hover">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('word.name')}</TableCell>
                    <TableCell align="left" hidden>
                      {t('word.document')}
                    </TableCell>
                    {Enum.arrPms.includes(props.pms) && (
                      <>
                        <TableCell align="left">
                          {t('word.checkinpms')}
                        </TableCell>
                        <TableCell align="left">
                          {t('word.checkoutpms')}
                        </TableCell>
                        <TableCell align="left">
                          {t('word.N-account')}
                        </TableCell>
                        <TableCell align="left">{t('word.uhpms')}</TableCell>
                      </>
                    )}
                    <TableCell align="left">{t('word.options')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers &&
                    customers.map((i) => (
                      <TableRow
                        key={i.idcustomer}
                        className={classes.tablerow}
                        onClick={() => clickCustomer(i.idcustomer)}>
                        <TableCell component="th" scope="row">
                          <p>{i.name}</p>
                          <span className="text-black-50 text-center">
                            {i.email}
                          </span>
                        </TableCell>
                        <TableCell align="left" hidden>
                          {i.document}
                        </TableCell>
                        {Enum.arrPms.includes(props.pms) && (
                          <>
                            <TableCell align="left">
                              {i.checkinpms
                                ? `${moment(i.checkinpms).format(
                                    t('format.date'),
                                  )}`
                                : ''}
                            </TableCell>
                            <TableCell align="left">
                              {i.checkoutpms
                                ? `${moment(i.checkoutpms).format(
                                    t('format.date'),
                                  )}`
                                : ''}
                            </TableCell>
                            <TableCell align="left">{i.idaccountpms}</TableCell>
                            <TableCell align="left">{i.uhpms}</TableCell>
                          </>
                        )}
                        <TableCell align="right">
                          <Fab
                            size="small"
                            aria-label="add"
                            color="primary"
                            title={t('word.select')}
                            onClick={() => clickCustomer(i.idcustomer)}>
                            <ArrowForward />
                          </Fab>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Card className="p-4 mb-4">
            <Grid container xs={12}>
              <div className="text-first font-weight-bold mt-2">
                {t('word.who')}
              </div>
            </Grid>

            <Grid container xs={12} sm={12} md={12} lg={12}>
              <TextField
                className="m-2"
                id="idperson"
                select
                required
                value={idperson}
                onChange={(e) => setIdPerson(e.target.value)}
                fullWidth>
                {person.map((option) => (
                  <MenuItem key={option.idperson} value={option.idperson}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Card>

          <Card className="p-4 mb-4">
            <Grid container xs={12}>
              <div className="text-first font-weight-bold mt-2">
                {t('word.activity')}
              </div>
            </Grid>

            <Grid container xs={12}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                locale={i18n.language}
                utils={MomentUtils}
                fullWidth>
                <DatePicker
                  label={t('word.schedule-date')}
                  className="m-2"
                  variant="inline"
                  disableToolbar
                  value={selectedDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={(e) =>
                    setSelectedDate(moment(e).format('YYYY/MM/DD'))
                  }
                  format="L"
                  autoOk
                  invalidDateMessage={t('message.invalid-date')}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid container xs={12} sm={12} md={12} lg={12}>
              <TextField
                className="m-2"
                id="idactivity"
                select
                label={t('word.activity')}
                required
                value={idactivity}
                onChange={(e) => setIdActivity(e.target.value)}
                fullWidth>
                {activities.map((option) => (
                  <MenuItem key={option.idactivity} value={option.idactivity}>
                    {option.activity}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {idactivity !== 0 &&
              periods &&
              (periods.errors || periods.length === 0) && (
                <Grid container xs={12} sm={12} md={12} lg={12}>
                  <MuiAlert severity="warning" style={{width: '100%'}}>
                    <div className="d-flex align-items-center align-content-center">
                      <span>
                        <strong className="d-block">{t('word.care')}!</strong>
                        {t('message.noperiod')}
                      </span>
                      <span
                        style={{fontSize: '12px'}}
                        className="ml-3 btn-pill m-1 badge badge-warning">
                        {moment(selectedDate).format(t('format.date'))}
                      </span>
                    </div>
                  </MuiAlert>
                </Grid>
              )}

            {periods &&
              periods.length > 0 &&
              periods.map((i) => (
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  key={i.professional}>
                  <div className="text-first font-weight-bold mt-2">
                    {i.professional}
                  </div>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <div className="d-flex flex-wrap justify-content-center">
                      {i.periods.map((j, k) => (
                        <div
                          key={`${j['professionals.idprofessional']}-${j['periods.idperiod']}-${j['periods.starttime']}`}>
                          {(j.left > 0 || activity.simultaneous === 0) && (
                            <Button
                              variant={
                                selectedProf ===
                                  j['professionals.idprofessional'] &&
                                selectedKey === k
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color={
                                selectedProf ===
                                  j['professionals.idprofessional'] &&
                                selectedKey === k
                                  ? 'primary'
                                  : 'primary'
                              }
                              className="m-2"
                              onClick={() => select(j, k)}>
                              <span className="btn-wrapper--label">
                                {`${j['periods.starttime']} ${t('word.at')} ${
                                  j['periods.endtime']
                                }`}
                              </span>
                              {j.left > 0 && activity.simultaneous > 1 && (
                                <span
                                  style={{
                                    backgroundColor:
                                      selectedProf ===
                                        j['professionals.idprofessional'] &&
                                      selectedKey === k
                                        ? 'white'
                                        : primaryColor,
                                    color:
                                      selectedProf ===
                                        j['professionals.idprofessional'] &&
                                      selectedKey === k
                                        ? primaryColor
                                        : '',
                                  }}
                                  className={
                                    selectedProf ===
                                      j['professionals.idprofessional'] &&
                                    selectedKey === k
                                      ? 'ml-3 btn-pill m-1 badge badge-info'
                                      : 'ml-3 btn-pill m-1 badge badge-primary'
                                  }>
                                  <b>
                                    {activity.simultaneous <= 1 ? '' : j.left}
                                  </b>

                                  {activity.simultaneous > 1 && j.left <= 1
                                    ? ` ${t('word.remaining')}`
                                    : ''}
                                  {activity.simultaneous > 1 && j.left > 1
                                    ? ` ${t('word.remainings')}`
                                    : ''}
                                </span>
                              )}
                            </Button>
                          )}
                        </div>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              ))}
          </Card>

          <div style={{float: 'right'}}>
            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              type="button"
              className="m-2"
              color="secondary"
              variant="contained"
              onClick={clickSchedule}>
              <CheckIcon />
              {t('word.schedule-new')}
            </Button>
          </div>
        </Grid>

        <Grid item xs={3} hidden>
          <Card className="p-4 mb-4">
            <Grid container xs={12}>
              <div className="text-first font-weight-bold mt-2">
                {t('word.schedule-new')}
              </div>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
