import Cookies from 'js-cookie';
import {doLogout} from './auth';

const BASE = process.env.REACT_APP_API_BASE;

const verifyHasErrors = (json) => {
  const company = Cookies.get('company');

  if (json.notAllowed) {
    doLogout();
    window.location.href = `/company/${company}`;
    return;
  }
  if (json.permission === false) {
    window.location.href = `/home`;
    return;
  }
  if (json.CompanyNotIntegratedPms === true) {
    window.location.href = `/home`;
  }
};

const fetchPost = async (rota, body) => {
  const company = Cookies.get('company');
  const token = Cookies.get('token');

  const res = await fetch(BASE + rota, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      company: `${company}`,
      token: `${token}`,
    },
    body: JSON.stringify(body),
  });

  const json = await res.json();

  verifyHasErrors(json);

  return json;
};

const fetchGet = async (rota) => {
  const company = Cookies.get('company');
  const token = Cookies.get('token');

  const res = await fetch(BASE + rota, {
    headers: {
      company: `${company}`,
      token: `${token}`,
    },
  });

  const json = await res.json();

  verifyHasErrors(json);

  return json;
};

const fetchFile = async (rota, body) => {
  const company = Cookies.get('company');
  const token = Cookies.get('token');

  const res = await fetch(BASE + rota, {
    method: 'POST',
    headers: {
      company: `${company}`,
      token: `${token}`,
    },
    body: body.fData,
  });

  const json = await res.json();

  verifyHasErrors(json);

  return json;
};

export const verifyLogin = async () => {
  const json = await fetchGet('user/verify');
  return json;
};

export const Login = async (email, password) => {
  const json = await fetchPost('user/login', {
    email,
    password,
  });
  return json;
};

export const Logout = async () => {
  const json = await fetchGet(`user/logout`);
  return json;
};

export const getUsers = async () => {
  const json = await fetchGet('user/');
  return json;
};

export const addUser = async (fData) => {
  const json = await fetchFile('user/new', {
    fData,
  });
  return json;
};

export const getUser = async (iduser) => {
  const json = await fetchGet(`user/${iduser}`);
  return json;
};

export const editUser = async (iduser, fData) => {
  const json = await fetchFile(`user/edit/${iduser}`, {fData});
  return json;
};

export const deleteUser = async (iduser) => {
  const json = await fetchGet(`user/delete/${iduser}`);
  return json;
};

export const editPassword = async (password, newpassword, confirmpassword) => {
  const json = await fetchPost(`user/change/password`, {
    password,
    newpassword,
    confirmpassword,
  });
  return json;
};

export const getTypeActivities = async () => {
  const json = await fetchGet('typeactivity/');
  return json;
};

export const getTypeActivity = async (idtypeactivity) => {
  const json = await fetchGet(`typeactivity/${idtypeactivity}`);
  return json;
};

export const addTypeActivity = async (typeactivity) => {
  const json = await fetchPost('typeactivity/new', {
    typeactivity,
  });
  return json;
};

export const editTypeActivity = async (
  idtypeactivity,
  typeactivity,
  active,
) => {
  const json = await fetchPost(`typeactivity/edit/${idtypeactivity}`, {
    typeactivity,
    active,
  });
  return json;
};

export const deleteTypeActivity = async (idtypeactivity) => {
  const json = await fetchGet(`typeactivity/delete/${idtypeactivity}`);
  return json;
};

export const getOccupations = async () => {
  const json = await fetchGet(`occupation/`);
  return json;
};

export const getOccupation = async (id) => {
  const json = await fetchGet(`occupation/${id}`);
  return json;
};

export const addOccupation = async (occupation) => {
  const json = await fetchPost('occupation/new', {
    occupation,
  });
  return json;
};

export const editOccupation = async (id, occupation) => {
  const json = await fetchPost(`occupation/edit/${id}`, {
    occupation,
  });
  return json;
};

export const deleteOccupation = async (id) => {
  const json = await fetchGet(`occupation/delete/${id}`);
  return json;
};

export const getActivities = async () => {
  const json = await fetchGet(`activity/`);
  return json;
};

export const getActivitiesActive = async () => {
  const json = await fetchGet(`activity/active`);
  return json;
};

export const getActivity = async (id) => {
  const json = await fetchGet(`activity/${id}`);
  return json;
};

export const addActivity = async (fData) => {
  const json = await fetchFile('activity/new', {
    fData,
  });
  return json;
};

export const getProfiles = async () => {
  const json = await fetchGet('profile/');
  return json;
};

export const getProfile = async (idprofile) => {
  const json = await fetchGet(`profile/${idprofile}`);
  return json;
};

export const addProfile = async (profile, permission) => {
  const json = await fetchPost('profile/new', {
    profile,
    permission,
  });
  return json;
};

export const editActivity = async (id, fData) => {
  const json = await fetchFile(`activity/edit/${id}`, {
    fData,
  });
  return json;
};

export const editProfile = async (idprofile, profile, permission) => {
  const json = await fetchPost(`profile/edit/${idprofile}`, {
    profile,
    permission,
  });
  return json;
};

export const deleteActivity = async (id) => {
  const json = await fetchGet(`activity/delete/${id}`);
  return json;
};

export const deleteProfile = async (idprofile) => {
  const json = await fetchGet(`profile/delete/${idprofile}`);
  return json;
};

export const getHolidays = async () => {
  const json = await fetchGet('holiday/');
  return json;
};

export const getHoliday = async (idholiday) => {
  const json = await fetchGet(`holiday/${idholiday}`);
  return json;
};

export const addHoliday = async (holiday, date) => {
  const json = await fetchPost('holiday/new', {
    holiday,
    date,
  });
  return json;
};

export const editHoliday = async (idholiday, holiday, date) => {
  const json = await fetchPost(`holiday/edit/${idholiday}`, {
    holiday,
    date,
  });
  return json;
};

export const deleteHoliday = async (idholiday) => {
  const json = await fetchGet(`holiday/delete/${idholiday}`);
  return json;
};

export const getPeriods = async (id) => {
  const json = await fetchGet(`period/activity/${id}`);
  return json;
};

export const getPeriod = async (id) => {
  const json = await fetchGet(`period/${id}`);
  return json;
};

export const addPeriod = async (idactivity, day, starttime, endtime) => {
  const json = await fetchPost('period/new', {
    idactivity,
    day,
    starttime,
    endtime,
  });
  return json;
};

export const editPeriod = async (id, idactivity, day, starttime, endtime) => {
  const json = await fetchPost(`period/edit/${id}`, {
    idactivity,
    day,
    starttime,
    endtime,
  });
  return json;
};

export const deletePeriod = async (id, idactivity) => {
  const json = await fetchGet(`period/delete/${id}?idactivity=${idactivity}`);
  return json;
};

export const getConfigs = async () => {
  const json = await fetchGet(`configuration/id`);
  return json;
};

export const getConfigsIcal = async () => {
  const json = await fetchGet(`configuration/route`);
  return json;
};

export const addConfig = async (fData) => {
  const json = await fetchFile('configuration/new', {
    fData,
  });
  return json;
};

export const editConfig = async (fData) => {
  const json = await fetchFile(`configuration/edit`, {
    fData,
  });
  return json;
};

export const getProfessionalActivity = async (id) => {
  const json = await fetchGet(`professional/activity/${id}`);
  return json;
};

export const getUsersActive = async (id) => {
  const json = await fetchGet(`user/active/${id}`);
  return json;
};

export const addProfessional = async (activity, user) => {
  const json = await fetchPost(`professional/new`, {
    activity,
    user,
  });
  return json;
};

export const getOneProfessional = async (id) => {
  const json = await fetchGet(`professional/${id}`);
  return json;
};

export const deleteProfessional = async (id) => {
  const json = await fetchGet(`professional/delete/${id}`);
  return json;
};

export const getHome = async (formatDate, day) => {
  const date = formatDate;
  const json = await fetchGet(`home/?date=${date}&day=${day}`);
  return json;
};

export const editSchedule = async (
  idschedule,
  iduser,
  idcustomer,
  oldstatus,
  newstatus,
  reason,
) => {
  const json = await fetchPost(`schedule/edit/${idschedule}`, {
    iduser,
    idcustomer,
    oldstatus,
    newstatus,
    reason,
  });
  return json;
};

export const editScheduleList = async (
  idactivity,
  starttime,
  endtime,
  newstatus,
  formatDate,
  reason,
  customer,
) => {
  const json = await fetchPost('schedule/edit/list', {
    idactivity,
    starttime,
    endtime,
    newstatus,
    formatDate,
    reason,
    customer,
  });
  return json;
};

export const getFilteredSchedule = async (
  dateInitial,
  dateFinal,
  idtypeactivity,
  idactivity,
  iduser,
  status,
  nro,
) => {
  const json = await fetchPost(`schedule/`, {
    dateInitial,
    dateFinal,
    idtypeactivity,
    idactivity,
    status,
    iduser,
    nro,
  });
  return json;
};

export const getFilteredSchedulePdf = async (
  dateInitial,
  dateFinal,
  idtypeactivity,
  nametypeactivity,
  idactivity,
  nameactivity,
  iduser,
  nameuser,
  status,
  status2,
  typeFilter,
  nro,
) => {
  const json = await fetchPost(`schedule/pdf`, {
    dateInitial,
    dateFinal,
    idtypeactivity,
    nametypeactivity,
    idactivity,
    nameactivity,
    status,
    status2,
    iduser,
    nameuser,
    typeFilter,
    nro,
  });
  return json;
};

export const getUserPdf = async () => {
  const json = await fetchGet(`user/pdf`);
  return json;
};

export const getSchedulePmsFilterPdf = async (
  first,
  last,
  actID,
  actName,
  usersID,
  usersName,
  status,
  status2,
  typeFilter,
  details,
  nro,
) => {
  const json = await fetchPost(`schedule/pms/pdf`, {
    dateInitial: first,
    dateFinal: last,
    idactivity: actID,
    nameactivity: actName,
    iduser: usersID,
    nameuser: usersName,
    integrated: status,
    integrated2: status2,
    typeFilter,
    details,
    nro,
  });
  return json;
};

export const getAllUsers = async () => {
  const json = await fetchGet(`professional/`);
  return json;
};

export const getActiveTypeActivities = async () => {
  const json = await fetchGet(`typeactivity/active`);
  return json;
};

export const getActiveActivities = async () => {
  const json = await fetchGet(`activity/active`);
  return json;
};

export const getConfigsUser = async () => {
  const json = await fetchGet(`profile/configs`);
  return json;
};

export const ping = async () => {
  const json = await fetchGet(`ping`);
  return json;
};

export const getPlaceOfSale = async () => {
  const json = await fetchGet(`placeofsale/`);
  return json;
};

export const getOnePoS = async (id) => {
  const json = await fetchGet(`placeofsale/${id}`);
  return json;
};

export const addPoS = async (placeofsale, idplaceofsalepms) => {
  const json = await fetchPost('placeofsale/new', {
    placeofsale,
    idplaceofsalepms,
  });
  return json;
};

export const editPoS = async (id, placeofsale, idplaceofsalepms) => {
  const json = await fetchPost(`placeofsale/edit/${id}`, {
    placeofsale,
    idplaceofsalepms,
  });
  return json;
};

export const deletePoS = async (id) => {
  const json = await fetchGet(`placeofsale/delete/${id}`);
  return json;
};

export const SchedulePmsFilter = async (
  firstD,
  lastD,
  actID,
  usersID,
  arr,
  nro,
) => {
  const json = await fetchPost('schedule/pms', {
    dateInitial: firstD,
    dateFinal: lastD,
    idactivity: actID,
    iduser: usersID,
    integrated: arr,
    nro,
  });
  return json;
};

export const schedulePmsIntegration = async (idschedule) => {
  const json = await fetchPost(`schedule/pms/integration`, {
    idschedule,
  });
  return json;
};

export const schedulePmsHistoric = async (id) => {
  const json = await fetchGet(`schedule/pms/historic/${id}`);
  return json;
};

export const getPms = async () => {
  const json = await fetchGet('configuration/pms');
  return json;
};

export const getPmsPending = async () => {
  const json = await fetchGet('schedule/pms/pending');
  return json;
};

export const getCountProfessionalScheduleDay = async () => {
  const json = await fetchGet('professional/schedule/count');
  return json;
};

export const CustomerFilter = async (body) => {
  const json = await fetchPost(`customer/filter`, {
    ...body,
  });
  return json;
};

export const getCustomer = async (id) => {
  const json = await fetchGet(`customer/${id}`);
  return json;
};

export const getPersonCustomer = async (id) => {
  const json = await fetchGet(`customer/person/${id}`);
  return json;
};

export const getAllScheduleCustomer = async (id) => {
  const json = await fetchGet(`customer/schedule/${id}`);
  return json;
};

export const getAllScheduleBeforeDayByProfessional = async () => {
  const json = await fetchGet(`schedule/before/day`);
  return json;
};

export const getAvailability = async (id, day, date, start, end) => {
  const json = await fetchGet(
    `availability/${id}?day=${day}&date=${date}&start=${start}&end=${end}`,
  );
  return json;
};

export const addSchedule = async (
  idactivity,
  date,
  idprofessional,
  starttime,
  endtime,
  idperson,
  confirm,
) => {
  const json = await fetchPost(`schedule/new`, {
    idactivity,
    date,
    idprofessional,
    starttime,
    endtime,
    idperson,
    confirm,
  });

  return json;
};

export const getCustomersAccountPms = async () => {
  const json = await fetchGet(`pms/customers/account`);
  return json;
};

export const addCustomerPms45 = async (fData) => {
  const json = await fetchPost('pms/customer/new', fData);
  return json;
};
