/* eslint-disable no-restricted-syntax */
import React, {Fragment, useEffect, useState} from 'react';
import {IconButton, Box, Tooltip} from '@material-ui/core';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import HomeIcon from '@material-ui/icons/Home';
import {useHistory} from 'react-router-dom';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {t} from 'i18next';
import Enum from '../../helpers/enum';

export default function SidebarFooter(props) {
  const history = useHistory();
  const find = [
    {
      key: 'home',
      pms: false,
      icon: <HomeIcon />,
      label: t('word.menu-bar.home'),
      verify: false,
      to: '/home',
    },
    {
      key: 'calendar',
      pms: false,
      icon: <CalendarTodayOutlined />,
      label: t('word.menu-bar.calendar'),
      verify: true,
      to: '/calendar',
    },
    {
      key: 'maintance',
      pms: true,
      icon: <AutorenewIcon />,
      label: t('word.menu-bar.maintenance'),
      verify: true,
      to: '/pms/schedule',
    },
  ];
  /* rafa aqui colocar icone de atalho para novo agendamento? */
  const [menuFooter, setMenuFooter] = useState([]);

  const handleMenuFooter = async (pms) => {
    const newPages = [];
    for (const i of find) {
      if (i.verify === true) {
        for (const y of props.pages) {
          for (const j of y.content)
            if (
              props.configs[j.key] &&
              props.configs[i.key] &&
              j.key === i.key
            ) {
              if (i.pms === true && Enum.arrPms.includes(pms)) {
                newPages.push(i);
              } else if (i.pms === false) {
                newPages.push(i);
              }
            }
        }
      } else if (i.verify === false) {
        if (i.pms === true && Enum.arrPms.includes(pms)) {
          newPages.push(i);
        } else if (i.pms === false) {
          newPages.push(i);
        }
      }
    }

    return newPages;
  };

  useEffect(() => {
    const get = async (pms) => {
      const res = await handleMenuFooter(pms);
      if (res) {
        setMenuFooter(res);
      }
    };

    if (props.pages) {
      get(props.pms);
    }
    // eslint-disable-next-line
  }, [props.pages]);

  return (
    <>
      <Box className="app-sidebar-footer-wrapper">
        <ul className="app-sidebar-footer">
          {menuFooter.map((i) => (
            <li>
              <Tooltip arrow title={i.label}>
                <IconButton onClick={() => history.push(i.to)}>
                  {i.icon}
                </IconButton>
              </Tooltip>
            </li>
          ))}
        </ul>
      </Box>
    </>
  );
}
