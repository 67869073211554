import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, Card} from '@material-ui/core';

import {useTranslation} from 'react-i18next';

import moment from 'moment';

import DoneAllIcon from '@material-ui/icons/DoneAll';

export default function ModalFeedback({
  visible,
  handleReload,
  date,
  periodSelected,
  handleClose,
}) {
  const {t} = useTranslation();

  const dateFormatted = useMemo(() => {
    if (date) {
      return moment(date).format(t('format.date'));
    }
    return '';
  }, [date, t]);

  const returnPage = async () => {
    handleReload();
  };

  const backHome = () => {
    handleClose();
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <Dialog open={visible} onClose={() => backHome()}>
        <Card className="card-box p-4 mb-4">
          <div className="text-center">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
                <DoneAllIcon
                  style={{fontSize: '40px'}}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <p
              className="mb-0 font-size-md text-muted"
              style={{paddingTop: '5px'}}>
              {t('message.schedule-success')}
            </p>
            <p className="mb-0 font-size-md text-muted pt-2">
              {dateFormatted}
              {` ${periodSelected['periods.starttime']} ${t('word.at')} ${
                periodSelected['periods.endtime']
              } `}
            </p>
            <h4 className="font-weight-bold mt-4">
              {t('message.schedule-new')}
            </h4>
            <div className="pt-4">
              <Button
                variant="outlined"
                color="secondary"
                className="mx-1"
                onClick={() => {
                  backHome();
                }}>
                <span className="btn-wrapper--label">{t('word.no')}</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="mx-1"
                onClick={returnPage}>
                <span className="btn-wrapper--label">{t('word.yes')}</span>
              </Button>
            </div>
          </div>
        </Card>
      </Dialog>
    </>
  );
}

ModalFeedback.defaultProps = {
  periodSelected: null,
  date: null,
  handleReload: () => {},
  handleClose: () => {},
};

ModalFeedback.propTypes = {
  visible: PropTypes.bool.isRequired,
  periodSelected: PropTypes.shape({}),
  date: PropTypes.instanceOf(Date),
  handleReload: PropTypes.func,
  handleClose: PropTypes.func,
};
