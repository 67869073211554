/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-brace-presence */
import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogTitle,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Fab,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {FaUserPlus} from 'react-icons/fa';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tablerow: {
    transition: 'all ease 0.2s',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
  clicktablerow: {
    transition: 'all ease 0.2s',
    backgroundColor: '#CCC',
  },
  hov: {
    cursor: 'pointer',
  },
}));

export default function ModalCustomersPms(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const {visible, handleClose, customers, clickImportCustomer} = props;

  const [customersPms, setCustomersPms] = useState([]);

  useEffect(() => {
    setCustomersPms(customers);
  }, []);

  // eslint-disable-next-line
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    let result = [];
    result = customers.filter(
      (row) => row.nometitular.toLowerCase().search(value) !== -1,
    );
    setCustomersPms(result);
  };

  return (
    <>
      <Dialog
        open={visible}
        onClose={() => handleClose()}
        aria-labelledby="form-dialog-title"
        maxWidth={'lg'}
        style={{minWidth: '600px'}}>
        <DialogTitle id="form-dialog-title">
          {t('word.pms-customer-accounts')}
        </DialogTitle>
        <div className="card-header">
          <FormControl className="w-100 m-2">
            <InputLabel htmlFor="input-with-icon-adornment">
              {t('word.search')}
            </InputLabel>
            <Input
              onChange={(event) => handleSearch(event)}
              style={{maxWidth: '500px'}}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <PerfectScrollbar>
          <Card className="p-4 mb-4">
            <div className={classes.root}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('word.name')}</TableCell>
                    <TableCell align="left" hidden>
                      {t('word.document')}
                    </TableCell>
                    <TableCell align="left">{t('word.checkinpms')}</TableCell>
                    <TableCell align="left">{t('word.checkoutpms')}</TableCell>
                    <TableCell align="left">{t('word.N-account')}</TableCell>
                    <TableCell align="left">{t('word.options')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customersPms &&
                    customersPms.map((i) => (
                      <TableRow key={i.idconta} className={classes.tablerow}>
                        <TableCell component="th" scope="row">
                          <p>{i.nometitular}</p>
                          <span className="text-black-50 text-center">
                            {i.nomehospede &&
                              i.nomehospede.length > 0 &&
                              i.nomehospede[0] !== '' &&
                              i.nomehospede.map((j) => `${j}, `)}
                            {i.nomehospede &&
                              (i.nomehospede.length === 0 ||
                                i.nomehospede[0] === '') &&
                              t('word.no-guests')}
                          </span>
                        </TableCell>
                        <TableCell align="left" hidden>
                          {i.documentoTitular}
                        </TableCell>
                        <TableCell align="left">
                          {i.dataIn
                            ? `${moment(i.dataIn).format(t('format.date'))}`
                            : ''}
                        </TableCell>
                        <TableCell align="left">
                          {i.dataOut
                            ? `${moment(i.dataOut).format(t('format.date'))}`
                            : ''}
                        </TableCell>
                        <TableCell align="left">{i.idconta}</TableCell>
                        <TableCell align="right">
                          <Fab
                            size="small"
                            aria-label="add"
                            color="primary"
                            title={t('word.import-customer')}
                            onClick={() => clickImportCustomer(i)}
                            disabled={
                              i.nomehospede &&
                              (i.nomehospede.length === 0 ||
                                i.nomehospede[0] === '')
                            }>
                            <FaUserPlus />
                          </Fab>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </Card>
        </PerfectScrollbar>
        <div style={{paddingBottom: '20px'}} className="text-center">
          <Button
            style={{height: '40px'}}
            onClick={() => handleClose()}
            variant="contained"
            color="primary"
            className="mx-1">
            <span className="btn-wrapper--label">{t('word.back')}</span>
          </Button>
        </div>
      </Dialog>
    </>
  );
}

ModalCustomersPms.defaultProps = {
  handleClose: () => {},
  clickImportCustomer: () => {},
};

ModalCustomersPms.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  clickImportCustomer: PropTypes.func,
};
