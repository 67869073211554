/* eslint-disable react/require-default-props */
import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import Settings from '@material-ui/icons/Settings';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Enum from 'helpers/enum';
import {
  Grid,
  Button,
  Card,
  TextField,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {makeStyles} from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import PersonIcon from '@material-ui/icons/Person';
import {Page} from 'store/reducers/Page/pageAction';
import Error from '../../Error';
import Delete from '../../ButtonDelete/index';
import {
  getProfile,
  editProfile,
  deleteProfile,
  addProfile,
} from '../../../helpers/api';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: '#fafafa',
      color: 'var(--tree-view-color)',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function Formulario(props) {
  const dispatch = useDispatch();
  const classes = useTreeItemStyles();
  const {t} = useTranslation();
  const history = useHistory();

  const [profile, setProfile] = useState('');
  const [result, setResult] = useState({});
  const [disable, setDisable] = useState(false);
  const [display, setDisplay] = useState('inline');

  const [user, setUser] = useState(false);
  const [typeactivity, setTypeactivity] = useState(false);
  const [occupation, setOccupation] = useState(false);
  const [activity, setActivity] = useState(false);
  const [profileP, setProfileP] = useState(false);
  const [holiday, setHoliday] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [config, setconfig] = useState(false);
  const [placeofsale, setPos] = useState(false);
  const [maintance, setMaintance] = useState(false);
  const [customerSearch, setCustomerSearch] = useState(false);
  const [querypms, setQueryPms] = useState(false);
  const [op, setOp] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setOp(false);
    setDisplay('inline');
    dispatch(Page(t('word.profile')));
    if (props.id) {
      const getOne = async (id) => {
        const res = await getProfile(id);
        if (res.errors) {
          // eslint-disable-next-line no-restricted-syntax
          for (const i of res.errors) {
            props.openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          }
          history.replace('/profile');
        } else {
          setProfile(res.profile);
          setUser(res.permission.user || false);
          setTypeactivity(res.permission.typeactivity || false);
          setOccupation(res.permission.occupation || false);
          setActivity(res.permission.activity || false);
          setProfileP(res.permission.profile || false);
          setHoliday(res.permission.holiday || false);
          setCalendar(res.permission.calendar || false);
          setSchedule(res.permission.schedule || false);
          setconfig(res.permission.config || false);
          setPos(res.permission.placeofsale || false);
          setMaintance(res.permission.maintance || false);
          setCustomerSearch(res.permission.customersearch || false);
          setQueryPms(res.permission.querypms || false);
        }
      };
      getOne(props.id);
    }
    setOp(true);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);

    let res;
    const permission = {
      user,
      typeactivity,
      occupation,
      activity,
      profile: profileP,
      holiday,
      calendar,
      schedule,
      config,
      placeofsale,
      maintance,
      customersearch: customerSearch,
      querypms,
    };

    if (props.id) {
      res = await editProfile(props.id, profile, permission);
      setResult(res);
    } else {
      res = await addProfile(profile, permission);
      setResult(res);
    }

    if (res && !res.errors) {
      if (props.id) {
        props.openAlert(
          t('message.edit-record', {param: t('word.profile')}),
          'success',
        );
      } else {
        props.openAlert(
          t('message.insert-record', {param: t('word.profile')}),
          'success',
        );
      }
      history.replace('/profile');
    } else {
      setDisable(false);
    }
  };

  const delProfile = async (id) => {
    const res = await deleteProfile(id);
    setResult(res);
    if (res && !res.errors) {
      props.openAlert(
        t('message.delete-record', {param: t('word.profile')}),
        'success',
      );
      history.replace('/profile');
    }
  };

  return (
    <>
      <Card className="p-4 mb-4">
        {result.errors && <Error result={result} />}
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={12}>
            <Grid item xs={12} sm={6}>
              <TextField
                className="m-2"
                label={t('word.profile')}
                id="standard-size-small"
                size="small"
                required
                disabled={disable}
                value={profile}
                onChange={(e) => setProfile(e.target.value)}
                fullWidth
                inputProps={{maxLength: 128}}
              />
            </Grid>
          </Grid>
          <div style={{float: 'right'}}>
            <Button
              style={{textTransform: 'none'}}
              onClick={() => history.replace('/profile')}
              disabled={disable}
              className="m-2"
              variant="contained"
              color="secondary">
              <ReplayIcon />
              {t('word.cancel')}
            </Button>
            <Delete func={delProfile} id={props.id} disabled={disable} />
            <Button
              style={{textTransform: 'none'}}
              disabled={disable}
              type="submit"
              className="m-2"
              color="secondary"
              variant="contained">
              <CheckIcon />
              {t('word.save')}
            </Button>
          </div>
        </form>
      </Card>
      <div
        style={{display: `${display}`, flexGrow: 1, backgroundColor: '#FFF'}}>
        <AppBar
          position="static"
          style={{borderRadius: '5px 5px 0px 0px', backgroundColor: '#FFF'}}>
          <Tabs
            textColor="secondary"
            value={value}
            onChange={handleChange}
            centered>
            <Tab label={t('word.permissions')} />
          </Tabs>
        </AppBar>
        {op === true && (
          <TabPanel value={value} index={0}>
            <TreeView
              className={classes.root}
              defaultExpanded={['1', '2', '3', '4']}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{width: 24}} />}>
              <StyledTreeItem
                nodeId="1"
                labelText={t('word.menu-bar.schedule')}
                labelIcon={CalendarTodayOutlined}>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox
                    onClick={() => setCalendar(!calendar)}
                    checked={calendar}
                  />
                  {t('word.menu-bar.calendar')}
                </div>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox
                    onClick={() => setSchedule(!schedule)}
                    checked={schedule}
                  />
                  {t('word.menu-bar.schedule-new')}
                </div>
              </StyledTreeItem>
              <StyledTreeItem
                nodeId="2"
                labelText={t('word.menu-bar.forms')}
                labelIcon={DashboardTwoToneIcon}>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox
                    onClick={() => setActivity(!activity)}
                    checked={activity}
                  />
                  {t('word.menu-bar.activities')}
                </div>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox
                    onClick={() => setPos(!placeofsale)}
                    checked={placeofsale}
                  />
                  {t('word.menu-bar.placesofsale')}
                </div>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox
                    onClick={() => setTypeactivity(!typeactivity)}
                    checked={typeactivity}
                  />
                  {t('word.menu-bar.type-activities')}
                </div>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox onClick={() => setUser(!user)} checked={user} />
                  {t('word.menu-bar.users')}
                </div>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox
                    onClick={() => setOccupation(!occupation)}
                    checked={occupation}
                  />
                  {t('word.menu-bar.occupations')}
                </div>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox
                    onClick={() => setProfileP(!profileP)}
                    checked={profileP}
                  />
                  {t('word.menu-bar.profiles')}
                </div>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox
                    onClick={() => setHoliday(!holiday)}
                    checked={holiday}
                  />
                  {t('word.menu-bar.holidays')}
                </div>
              </StyledTreeItem>
              <StyledTreeItem
                nodeId="3"
                labelText={t('word.menu-bar.configs')}
                labelIcon={Settings}>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox
                    onClick={() => setconfig(!config)}
                    checked={config}
                  />
                  {t('word.menu-bar.company')}
                </div>
              </StyledTreeItem>
            </TreeView>
            <TreeView
              className={classes.root}
              defaultExpanded={['4']}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{width: 24}} />}>
              <StyledTreeItem
                nodeId="4"
                labelText={t('word.menu-bar.customers')}
                labelIcon={PersonIcon}>
                <div style={{paddingLeft: '40px'}}>
                  <Checkbox
                    onClick={() => setCustomerSearch(!customerSearch)}
                    checked={customerSearch}
                  />
                  {t('word.menu-bar.search')}
                </div>
              </StyledTreeItem>
            </TreeView>
            {Enum.arrPms.includes(props.pms) && (
              <>
                <TreeView
                  className={classes.root}
                  defaultExpanded={['5']}
                  defaultCollapseIcon={<ArrowDropDownIcon />}
                  defaultExpandIcon={<ArrowRightIcon />}
                  defaultEndIcon={<div style={{width: 24}} />}>
                  <StyledTreeItem
                    nodeId="5"
                    labelText={t('word.menu-bar.integration-pms')}
                    labelIcon={AutorenewIcon}>
                    <div style={{paddingLeft: '40px'}}>
                      <Checkbox
                        onClick={() => setMaintance(!maintance)}
                        checked={maintance}
                      />
                      {t('word.menu-bar.maintenance')}
                    </div>
                  </StyledTreeItem>
                </TreeView>
              </>
            )}
          </TabPanel>
        )}
        {Enum.arrPms.includes(props.pms) && (
          <>
            <div className="heading-3 my-2">{t('word.permission-special')}</div>
            <div style={{paddingLeft: '20px'}}>
              <Checkbox
                onClick={() => setQueryPms(!querypms)}
                checked={querypms}
              />
              {t('word.querypms')}
            </div>
          </>
        )}
      </div>
    </>
  );
}
